/* empty */

.websiteColors {
    --mnq-error: #A41E35;
    --mnq-error-bg: #ffe7e9;
    --mnq-error-border: #FFA690;
    --mnq-success: #23d100;
    --mnq-success-bg: #EAFAE6;
    --mnq-success-border: #A8EA97;
    --mnq-medium: #ffae51;
    --mnq-medium-bg: #FFF4E4;
    --mnq-medium-border: #FFD093;

    --mnq-primary: #A41E35;
    --mnq-primary-bg: #ffbfca;
    --mnq-secondary: #fdb913;
    --mnq-secondary-bg: #fdee68;
    --mnq-accessibility-bg: #b98e31;
    --mnq-dark-01: #3c3c3c;
    --mnq-dark-02: #141414;
    --mnq-bdr-color: #949494;
    --mnq-placeholder: #767676;

    --mnq-light-01: #c2c2c2;
    --mnq-light-02: #dedede;
    --mnq-light-03: #E1E3E6;
    --mnq-light-04: #F2EFE8;
    --mnq-light-05: #fff;
    --mnq-light-06: #f0f0f0;
    --mnq-light-07: #aeaeae;

    --mnq-select-bg: #43AC6A;
}

.strikethrough-text {
    text-decoration: line-through;
    color: inherit;
}

.tblTitle.strikethrough-text a {
    text-decoration: line-through underline;
    color: inherit;
}

.justify-align {
    width: 16px;
    height: 16px;
}

.heading {
    font-weight: 400;
}

.font-select {
    width: 150px;
    min-width: 150px;
    max-width: 200px;
}

.disabled-editor {
    background-color: #efefef;
}

.ant-upload-picture-card-wrapper:focus-within .ant-upload.ant-upload-select {
    outline: 2px solid #c9858a;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
    border-radius: 3px;
    z-index: 1;
}

.explore-question-card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.explore-question-card-active {
    background-color: #c8e6c9a3
}

.preview-criteria-card {
    margin: 8px;
    margin-right: 14px;
    border: 1 px solid black;
}

.filter-question-option {
    display: flex;
    align-items: center;
}

.filter-question-options-block {
    display: flex;
    margin: 5px;
    font-size: small;
    background-color: #f0f0f0;
    align-items: center;
    min-width: fit-content;
}

.filter-option-text {
    padding-left: 5px;
}

.filter-question-options-block button {
    border: none;
    background-color: inherit;
    border-radius: 2px;
}

.profileViewFilterBlk {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profileViewFilterBlk .profileViewSortActions {
    display: flex;
}

.profileViewFilterBlk .profileViewSortActions button.inactive {
    color: var(--mnq-bdr-color);
}

.profileViewFilterBlk .ant-btn>.anticon+span {
    margin-inline-start: 2px;
}

.previewLegendsWrapper {
    margin-top: 10px;
}

.previewLegendsWrapper li {
    display: flex;
    align-items: center;
}

.previewLegendsWrapper li span {
    margin: 2px;
}

.previewLegendsWrapper .legendColor {
    height: 15px;
    width: 15px;
    display: block;
}

.previewCardFlipContainer {
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.previewCardFlipContainer.flipped {
    transform: rotateY(180deg);
}

.siteInfoBlkFooter {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0 0;
}

.siteInfoBlkFooter button:not(:last-child) {
    margin-right: 8px;
}

/** Caution Note TextArea Custom Styles */
.textAreaWrapper textarea {
    resize: vertical !important;
}

.blinkSiteRow,
.blinkSiteRow .ant-table-column-sort {
    animation: blinkRow 7s ease-in-out !important;
}

@keyframes blinkRow {
    0% {
        background-color: var(--mnq-primary-bg);
    }

    100% {
        background-color: inherit;
    }
}

.disabled-date-overlay {
    position: relative;
    display: inline-block;
}

.disabled-date-overlay::before {
    content: '';
    position: absolute;
    top: 15%;
    left: 30%;
    width: 100%;
    height: 2px;
    background-color: var(--mnq-error-border);
    transform-origin: top right;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 1;
}

.promtHide {
    position: absolute; border: 0px; width: 1px; height: 1px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;
}