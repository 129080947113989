a,
.btnStyle1,
.btnStyle2,
.btnStyle3,
.customCheck input[type="checkbox"]+label::before,
.customCheck input[type="checkbox"]+label::after,
.customRadio input[type="radio"]+label::before,
.customRadio input[type="radio"]+label::after,
button,
input,
select,
textarea,
.anticon,
svg,
.headerMenu,
.menuControl,
.closeBlk,
.qstActions,
.userOptions>ul li>span,
.userOptions>ul ul li,
.previewFilterControl,
.overlay {
  transition: all 0.3s;
}

.websiteColors {
  --mnq-error: #A41E35;
  --mnq-error-bg: #ffe7e9;
  --mnq-error-border: #FFA690;
  --mnq-success: #23d100;
  --mnq-success-txt: #43AC6A;
  --mnq-success-bg: #EAFAE6;
  --mnq-success-border: #A8EA97;
  --mnq-medium: #ffae51;
  --mnq-medium-bg: #FFF4E4;
  --mnq-medium-border: #FFD093;

  --mnq-primary: #A41E35;
  --mnq-primary-bg: #ffbfca;
  --mnq-secondary: #fdb913;
  --mnq-secondary-bg: #fdee68;
  --mnq-accessibility-bg: #b98e31;
  --mnq-dark-01: #3c3c3c;
  --mnq-dark-02: #141414;
  --mnq-bdr-color: #949494;
  --mnq-placeholder: #767676;

  --mnq-light-01: #c2c2c2;
  --mnq-light-02: #dedede;
  --mnq-light-03: #E1E3E6;
  --mnq-light-04: #F2EFE8;
  --mnq-light-05: #fff;
  --mnq-light-06: #f0f0f0;
  --mnq-light-07: #aeaeae;

  --mnq-select-bg: #43AC6A
}

.websiteLayout {
  --radius-1: 4px;
  --radius-2: 8px;
  --radius-round: 100px;
  --shadow-1: 0 0 10px rgba(0, 0, 0, 0.2);
}

.websiteFonts {
  --mnq-font-1: 'Roboto', sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  /* overflow-y: scroll; */
}

.scOff {
  max-height: 100vh;
  overflow: hidden;
}

body {
  font-family: var(--mnq-font-1);
  font-size: 0.875rem;
  line-height: 1.5;
  word-break: break-word;
  font-weight: 400;
  color: var(--mnq-dark-01);
}

table {
  border-collapse: collapse;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--mnq-light-05) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--mnq-light-05) inset !important;
  -webkit-text-fill-color: var(--mnq-dark-01) !important;
}

input,
.ant-input,
textarea,
select {
  width: 100%;
  display: block;
  font-size: inherit;
  outline: none;
  font-family: var(--mnq-font-1);
  font-weight: inherit;
  border-radius: var(--radius-1);
  line-height: 1.5714285714285714;
  padding: 4px 11px;
  margin: 0;
  background-color: var(--mnq-light-05);
  border: 1px solid var(--mnq-bdr-color);
  color: var(--mnq-dark-01);
}

input:disabled,
.ant-input:disabled,
textarea:disabled,
select:disabled {
  border-color: var(--mnq-light-03);
  color: var(--mnq-dark-02);
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.04);
}

.tableSettingBlk input:disabled,
.tableSettingBlk .ant-input:disabled,
.tableSettingBlk textarea:disabled,
.tableSettingBlk select:disabled,
.tableSettingBlk .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--mnq-light-05);
  opacity: 0.7;
}

.ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector,
input.ant-input-disabled:focus,
.ant-input.ant-input-disabled:focus,
.ant-input.ant-input-disabled:hover,
.ant-input-search .ant-input.ant-input-disabled:hover,
.ant-input-search .ant-input.ant-input-disabled:focus,
select.ant-input-disabled:focus,
textarea.ant-input-disabled:focus {
  border-color: var(--mnq-light-03);
}

.ant-input-search .ant-input.ant-input-disabled:hover+.ant-input-group-addon .ant-btn,
.ant-input-search>.ant-input-group>.ant-input-disabled+.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover {
  border-color: #d9d9d9;
  color: var(--mnq-dark-02);
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-search>.ant-input-group>.ant-input-disabled+.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover .ant-btn-icon {
  color: var(--mnq-dark-02);
}

input:not(:disabled):focus,
.ant-input:not(:disabled):focus,
.ant-input:not(:disabled):hover,
.ant-input-search .ant-input:not(:disabled):hover,
.ant-input-search .ant-input:not(:disabled):focus,
select:not(:disabled):focus,
textarea:not(:disabled):focus {
  box-shadow: none;
  background-color: var(--mnq-light-05);
  border-color: var(--mnq-dark-01);
  color: var(--mnq-dark-01);
}

textarea {
  height: 100px;
  resize: none;
  max-width: 100%;
}

select {
  -webkit-appearance: none;
  padding-right: 26px;
  background: var(--mnq-light-05) url(../images/icon-arrow-down.svg) no-repeat calc(100% - 10px) center / 10px;
}

.fldError {
  border-color: var(--mnq-error);
}

.fldError:focus {
  border-color: var(--mnq-error);
}

.errorColor,
.requiredTxt {
  color: var(--mnq-error);
}

.mediumColor {
  color: var(--mnq-medium);
}

.successColor {
  color: var(--mnq-success);
}

.msgError {
  display: block;
  font-size: 0.75rem;
  color: var(--mnq-error);
  margin-top: 2px;
}

button {
  font-family: inherit;
  outline: none;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.25;
}

p {
  margin-bottom: 20px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
  margin-bottom: 0;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.5rem;
}

img,
video {
  border: none;
  max-width: 100%;
}

a,
.linkBtn {
  color: var(--mnq-primary);
  text-decoration: underline;
  cursor: pointer;
}

button.linkBtn {
  border: none;
  background: transparent;
}

a:hover,
a:focus,
.linkBtn:hover,
.linkBtn:focus {
  text-decoration: none;
  color: inherit;
}

select:focus-visible,
button:focus-visible,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:focus-visible .ant-table-column-title,
.ant-select-focused.ant-select:not(.ant-select-open):focus-visible,
.footerMenu li a:focus-visible,
.ant-pagination .ant-pagination-prev:focus-visible,
.ant-pagination .ant-pagination-next:focus-visible,
.ant-btn:not(:disabled):focus-visible,
.fldShowHide .iconShowHide:focus-visible {
  outline: 2px solid #c9858a;
  outline-offset: 1px;
  transition: outline-offset 0s, outline 0s;
  border-radius: 3px;
  z-index: 1;
}

a:focus-visible {
  transition: outline-offset 0s, outline 0s;
  border-radius: 3px;
  background-color: var(--mnq-accessibility-bg);
  text-decoration: none;
  color: var(--mnq-light-05) !important;
}

.actionBtn:not(:disabled):focus-visible {
  border-radius: 50%;
}

.footerMenu li a:focus-visible,
.headerMenu li a:focus-visible {
  background-color: transparent;
}

a,
input,
img,
select,
textarea {
  outline: 0 none;
}

* {
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::placeholder {
  color: var(--mnq-placeholder) !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--mnq-light-02);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--mnq-dark-01);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--mnq-primary);
}

hr {
  border: 1px solid var(--mnq-light-02);
  border-width: 1px 0 0;
  margin: 30px 0;
  height: 0;
  width: 100%;
}

.noGap {
  margin: 0;
}

strong {
  font-weight: 700;
}

.alignLeft {
  text-align: left !important;
}

.alignCenter {
  text-align: center !important;
}

.alignRight {
  text-align: right !important;
}

.flexTopGap {
  margin-top: auto !important;
}

.flexLeftGap {
  margin-left: auto !important;
}

.flexJCenter {
  justify-content: center !important;
}

.flexJStart {
  justify-content: flex-start !important;
}

.flexJEnd {
  justify-content: flex-end !important;
}

.flexAlignCenter {
  align-items: center !important;
}

.flexAlignStart {
  align-items: flex-start !important;
}

.flexAlignEnd {
  align-items: flex-end !important;
}

.topGap1 {
  margin-top: 20px !important;
}

.topGap2 {
  margin-top: 30px !important;
}

.noTopGap {
  margin-top: 0 !important;
}

.gap10 {
  margin-bottom: 10px !important;
}

.bottomGap1 {
  margin-bottom: 20px !important;
}

.bottomGap2 {
  margin-bottom: 30px !important;
}

.noBottomGap {
  margin-bottom: 0 !important;
}

.posRelative {
  position: relative !important;
}

.posAbsolute {
  position: absolute !important;
}

.posFixed {
  position: fixed !important;
}

.displayNone {
  display: none !important;
}

.displayBlock {
  display: block !important;
}

.displayFlex {
  display: flex !important;
}

.displayInlineFlex {
  display: inline-flex !important;
}

.flexVertical {
  flex-direction: column;
  flex-wrap: nowrap !important;
}

.fullWidth {
  width: 100%;
}

h1.siteLogo {
  font-size: 0;
  margin: 0;
}

.cursorPointer {
  cursor: pointer !important;
}

.cursorDefault {
  cursor: default !important;
}

.listStyle {
  list-style-type: disc;
  margin-left: 20px;
}

.listStyle li {
  margin-bottom: 8px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 20px 24px;
}

.btnStyle1,
.ant-btn-primary,
.ant-btn-default,
.btnStyle2,
.btnStyle3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid;
  padding: 4px 15px;
  border-radius: var(--radius-1);
  text-decoration: none;
  box-shadow: none;
}

.ant-modal-footer .ant-btn {
  display: inline-flex;
}

.blinkingBtn {
  animation: 1500ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s infinite normal none running glowing;
}

@keyframes glowing {

  0%,
  33% {
    box-shadow: 0 0 0 0 var(--mnq-primary), 0 0 0 rgba(164, 30, 53, 1);
  }

  66%,
  100% {
    box-shadow: 0 0 0 0 var(--mnq-primary), 0 0 0 10px rgba(164, 30, 53, 0.01);
  }
}

.ant-btn-primary,
.btnStyle1 {
  color: var(--mnq-light-05);
  background-color: var(--mnq-primary);
  border-color: var(--mnq-primary);
}

.btnStyle1:disabled,
.btnStyle1:disabled:hover {
  background-color: var(--mnq-primary);
  border-color: var(--mnq-primary);
  opacity: 0.5;
  cursor: not-allowed;
}

.btnStyle3:disabled,
.btnStyle3:disabled:hover {
  background-color: var(--mnq-light-03);
  border-color: var(--mnq-light-03);
  cursor: not-allowed;
}

.ant-btn-default,
.btnStyle3 {
  color: var(--mnq-dark-02);
  border-color: var(--mnq-bdr-color);
  background-color: var(--mnq-light-05);
}

.ant-btn-default:not(:disabled):hover,
.ant-btn-default:not(:disabled):focus,
.btnStyle3:not(:disabled):hover,
.btnStyle3:not(:disabled):focus {
  background-color: var(--mnq-light-03);
  color: var(--mnq-dark-02);
  border-color: var(--mnq-light-03);
}

.btnStyle2 {
  color: var(--mnq-light-05);
  background-color: var(--mnq-dark-02);
  border-color: var(--mnq-dark-02);
}

.ant-btn-primary:not(:disabled):hover,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary:not(:disabled):focus,
.btnStyle1:not(:disabled):hover,
.btnStyle1:not(:disabled):focus,
.btnStyle2:not(:disabled):hover,
.btnStyle2:not(:disabled):focus {
  background-color: var(--mnq-dark-01);
  border-color: var(--mnq-dark-01);
  color: var(--mnq-light-05);
}

.iconOnlyBtn,
.iconOnlyBtn:not(:disabled):hover,
.iconOnlyBtn:not(:disabled):focus {
  padding: 0;
  background: transparent;
  border: none;
  margin: 0 3px;
  min-height: 0;
  line-height: 1;
}

.actionBtn {
  width: 28px;
  height: 28px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 50%;
}

.actionBtn:not(:disabled):hover,
.actionBtn:not(:disabled):focus {
  color: var(--mnq-primary);
}

.iconOnlyBtn.ant-btn-default:disabled {
  background: none;
}

.btnOk {
  color: var(--mnq-success);
}

.iconOnlyBtn svg {
  width: 20px;
  height: 20px;
}

.iconOnlyBtn.smallIcon.resetBtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.iconOnlyBtn.smallIcon svg {
  width: 16px;
  height: 16px;
}

.iconOnlyBtn.cautionBtn svg {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}

.fldWrap {
  margin-bottom: 20px;
}

.fldWrap.smlFldWrap {
  display: inline-flex;
}

label {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
}

label .supportedLabel {
  font-weight: 400;
  margin-left: 5px;
}

.rowBlk {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

.rowBlk .colBlk3,
.rowBlk .colBlk4,
.rowBlk .colBlk6,
.rowBlk .colBlk12 {
  padding: 0 15px;
}

.rowBlk .colBlk12 {
  flex: 0 0 100%;
  width: 100%;
}

.rowBlk .colBlk6 {
  flex: 0 0 50%;
  width: 50%;
}

.rowBlk .colBlk4 {
  flex: 0 0 33.33%;
  width: 33.33%;
}

.rowBlk .colBlk3 {
  flex: 0 0 25%;
  width: 25%;
}

.rowBlk .leftGap3 {
  margin-left: 25%;
}

.rowBlk .leftGap4 {
  margin-left: 33.33%;
}

.rowBlk .leftGap6 {
  margin-left: 50%;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 60px;
}

.loginWrapper {
  display: flex;
  text-align: center;
}

.loginWrapper .fldWrap {
  text-align: left;
}

.loginWrapper>* {
  flex: 1;
  height: 100vh;
}

.loginWrapper .picBlk {
  font-size: 0;
  background: url(../images/login-pic.jpg) no-repeat center center / cover;
}

.loginFormWrap {
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginFormWrap label {
  text-align: left;
}

.loginFormWrap .formBlk {
  max-width: 440px;
  padding: 8px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.supportLink {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.supportLink a:not(:first-child) {
  margin-left: 5px;
}

.regSuccess {
  border: 1px solid var(--mnq-success-bg);
  padding: 50px 16px 16px;
  border-radius: var(--radius-1);
  background: var(--mnq-success-bg) url(../images/icon-yes.svg) no-repeat center 16px / 20px;
}

.regSuccess p {
  margin-bottom: 6px;
}

.loginFormWrap .formBlk h2 {
  margin-bottom: 8px;
  font-size: 1.5rem;
}

.loginFormWrap .formBlk p+.fldWrap {
  padding-top: 10px;
}

.loginFormWrap .formBlk .btnStyle1 {
  width: 100%;
}

.fldShowHide {
  position: relative;
}

.fldShowHide input {
  padding-right: 36px;
}

.fldShowHide .iconShowHide {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: url(../images/icon-show.svg) no-repeat center center / 100%;
  font-size: 0;
  cursor: pointer;
  border: none;
  outline: none;
}

.fldShowHide .iconShowHide.active {
  background-image: url(../images/icon-hide.svg);
}

.fldShowHide .iconShowHide:focus {
  background-color: var(--mnq-light-03);
}

.forgotPassword {
  margin-top: 4px;
  text-align: right;
}

.termsCondition {
  font-weight: 500;
  margin-top: 16px;
  font-size: 0.75rem;
}

.twoColControl {
  display: flex;
  margin: 0 -8px 24px;
  flex-wrap: wrap;
}

.customControl {
  display: flex;
  flex-direction: column;
}

label+.customControl {
  margin-top: 12px;
}

.customControl .customCheck:not(:last-child),
.customControl .customRadio:not(:last-child) {
  margin-bottom: 10px;
}

.customCheck,
.customRadio {
  text-align: left;
  position: relative;
  line-height: 18px;
}

.customCheck label,
.customRadio label {
  cursor: pointer;
  padding-left: 24px;
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  line-height: 1.4;
}

.customCheck label.ant-radio-wrapper-disabled,
.customRadio label.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}

.twoColControl .customCheck,
.twoColControl .customRadio {
  padding: 0 8px;
  flex: 0 0 50%;
}

.customCheck input[type="checkbox"],
.customRadio input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.boxCustomControl .customCheck label,
.boxCustomControl .customRadio label {
  padding: 12px 12px 12px 36px;
  border: 1px solid var(--mnq-bdr-color);
  background-color: var(--mnq-light-05);
  border-radius: var(--radius-1);
  width: 100%;
}

.boxCustomControl .customCheck input[type="checkbox"]:checked+label,
.boxCustomControl .customRadio input[type="radio"]:checked+label {
  border-color: var(--mnq-primary);
}

.customCheck input[type="checkbox"]+label::before,
.customRadio input[type="radio"]+label::before {
  content: "";
  background: var(--mnq-light-05);
  border-radius: 2px;
  border: 1px solid var(--mnq-light-01);
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  cursor: pointer;
}

.customRadio input[type="radio"]+label::before {
  border-radius: 50%;
}

.customCheck input[type="checkbox"]:checked+label::before {
  border: 1px solid var(--mnq-primary);
  background: var(--mnq-primary) url(../images/icon-tick.svg) no-repeat center center / 10px 8px;
}

.customRadio input[type="radio"]:checked+label::before {
  border: 1px solid var(--mnq-primary);
  background: var(--mnq-light-05);
}

.boxCustomControl .customCheck input[type="checkbox"]+label::before,
.boxCustomControl .customRadio input[type="radio"]+label::before {
  left: 12px;
  top: 12px;
}

.customRadio input[type="radio"]+label::after {
  content: "";
  background: var(--mnq-light-05);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
}

.customRadio input[type="radio"]:checked+label::after {
  background: var(--mnq-primary);
}

.boxCustomControl .customRadio input[type="radio"]+label::after {
  left: 16px;
  top: 16px;
}

.mainWrapper header {
  padding: 8px 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 99;
}

.mainWrapper footer {
  padding: 24px;
}

.mainWrapper footer.slimFooter {
  padding: 12px 24px;
}

.mainWrapper header,
.mainWrapper footer {
  display: flex;
  background: var(--mnq-dark-02);
  color: var(--mnq-light-05);
  align-items: center;
}

.mainWrapper header a,
.mainWrapper footer a {
  color: inherit;
  display: block;
}

.mainWrapper header a {
  text-decoration: none;
}

.mainWrapper header h1 a {
  border-radius: 50%;
}

.mainWrapper header h1 a:focus-visible {
  box-shadow: 0 4px 0 var(--mnq-accessibility-bg);
  background: transparent;
}

.mainWrapper header h1 {
  margin: 0 24px 0 0;
}

h1 img {
  display: block;
}

.headerMenu {
  display: flex;
}

.headerMenu li:not(:last-child) {
  margin-right: 24px;
}

.headerMenu li a {
  display: block;
  padding: 5px 0;
  border: solid transparent;
  border-width: 0 0 3px;
}

.headerMenu li a:hover,
.headerMenu li a:focus,
.headerMenu li a.active {
  border-color: var(--mnq-secondary);
}

.headerMenu li a:focus-visible {
  color: var(--mnq-secondary) !important;
}

.userProfile {
  margin-left: auto;
  padding: 5px 0;
  position: relative;
}

.userProfile .userBlk {
  color: var(--mnq-light-05);
  border: none;
  background: url(../images/dropdown-arrow.svg) no-repeat right center;
  padding-right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.userProfile .userAvatar {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  background: var(--mnq-error);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.userQuickView {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.userQuickView .userOrg {
  font-size: 0.675rem;
  color: var(--mnq-secondary-bg);
  margin-top: 5px;
}

.userOptions {
  position: absolute;
  right: -10px;
  top: 100%;
  background: var(--mnq-light-05);
  width: 260px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-1);
  color: var(--mnq-dark-01);
  padding: 5px 0;
}

.userOptions .userEmail {
  padding: 5px 20px 10px;
  border-bottom: 1px solid var(--mnq-light-04);
  display: none;
}

.userOptions>ul li {
  margin-bottom: 1px;
}

.userOptions ul ul {
  margin: 0 10px 10px 16px;
  border-left: 1px solid var(--mnq-light-03);
}

.userOptions ul ul li {
  position: relative;
  margin-left: 10px;
}

.userOptions ul ul li::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  width: 10px;
  height: 1px;
  background-color: var(--mnq-light-03);
}

.userOptions ul li span,
.userOptions ul li a {
  display: block;
  padding: 10px 16px;
}

.userOptions ul ul button {
  border: none;
  background: transparent;
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  cursor: pointer;
}

.userOptions ul ul button:focus-visible {
  border-radius: 3px;
  background-color: var(--mnq-accessibility-bg);
  text-decoration: none;
  color: var(--mnq-light-05);
  outline: none;
}

.userOptions ul li a:hover,
.userOptions ul ul button:hover,
.userOptions ul li.active a {
  color: var(--mnq-primary);
  background: var(--mnq-light-04);
  cursor: pointer;
}

.userOptions ul ul li button:disabled,
.userOptions ul ul li button:disabled:hover {
  background: transparent;
  color: var(--mnq-bdr-color);
  cursor: not-allowed;
}

.menuControl {
  margin-left: 16px;
  background: url(../images/menu-icon.svg) no-repeat center center / 100%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 0;
  display: none;
}

.menuControl.active {
  background-image: url(../images/icon-close.svg);
  background-size: 75%;
}

.footerLeft img {
  display: block;
}

.footerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}

.footerSocial {
  display: flex;
  margin-bottom: 24px;
}

.footerSocial a {
  display: block;
}

.footerSocial a:not(:first-child) {
  margin-left: 16px;
}

.footerSocial img {
  display: block;
}

.footerMenu {
  display: flex;
  flex-wrap: wrap;
}

.footerMenu li {
  position: relative;
  margin: 3px 0;
}

.footerMenu li a:focus {
  text-decoration: none;
}

.footerMenu li:not(:last-child) {
  padding-right: 20px;
}

.footerMenu li:not(:last-child)::before {
  content: '';
  height: 14px;
  width: 1px;
  position: absolute;
  right: 10px;
  top: 4px;
  background-color: var(--mnq-light-05);
}

.slimFooter .footerRight {
  flex-direction: row;
  align-items: center;
}

.slimFooter .footerSocial {
  margin: 0 30px;
}

.slimFooter .footerSocial a:not(:first-child) {
  margin-left: 12px;
}

.slimFooter .footerSocial img {
  width: 28px;
}

.floatingBlk {
  position: fixed;
  display: none;
  animation: 0.6s slide-left;
  z-index: 99;
}

.floatingBlk.active {
  display: flex;
  flex-direction: column;
  top: 0;
  background: var(--mnq-light-05);
  width: 690px;
  height: 100%;
  right: 0;
  box-shadow: 0px 4px 8px rgba(58, 58, 68, 0.16), 0px 8px 16px rgba(90, 91, 106, 0.16);
  transition: all .6s ease-in-out 0s;
}

@keyframes slide-left {
  from {
    margin-right: -100%;
  }

  to {
    margin-right: 0%;
  }
}

@keyframes slide-right {
  from {
    margin-right: 0;
  }

  to {
    margin-right: -100%;
  }
}

.floatingBlk .blkHeading {
  border-bottom: 1px solid var(--mnq-light-03);
  font-size: 1.375rem;
  color: var(--mnq-dark-02);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.blkFooter {
  border-top: 1px solid var(--mnq-light-03);
  display: flex;
  justify-content: flex-end;
  padding: 12px 0 0;
}

.blkFooter button:not(:last-child) {
  margin-right: 8px;
}

.floatingBlk .blkContent {
  flex: 1;
  overflow-y: auto;
}

.floatingBlk .blkHeading,
.floatingBlk .blkFooter,
.floatingBlk .blkContent {
  padding: 12px 24px;
}

.closeBlk {
  font-size: 0;
  width: 24px;
  height: 24px;
  background: url(../images/icon-close-2.svg) no-repeat center center;
  cursor: pointer;
  margin-left: auto;
  opacity: 0.5;
  border: none;
  box-shadow: none;
  padding: 0;
}

.closeBlk:hover,
.closeBlk:focus {
  opacity: 1;
}

.pageHeadingWrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.pageHeadingWrap h2 {
  margin: 0;
}

.tableSettingBlk {
  padding: 10px 16px;
  background: var(--mnq-light-04);
  border-radius: var(--radius-1) var(--radius-1) 0 0;
  display: flex;
  align-items: center;
}

.tableSettingBlk .ant-form-item {
  margin-bottom: 0;
}

.tableSettingBlk .ant-form-item .ant-form-item-label>label {
  margin-bottom: 0;
  margin-right: 5px;
}

.tblHeadingFldWrap {
  display: flex;
  align-items: center;
}

.tblHeadingFldWrap label {
  margin-right: 5px;
}

.pagerWrap {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.pageOption .ant-select {
  margin: 0 5px;
}

.cardHead .withFav .anticon,
.tblTitle .anticon {
  margin-right: 5px;
  color: var(--mnq-light-07);
}

.cardHead .withFav .anticon.active,
.tblTitle .anticon.active {
  color: var(--mnq-medium);
}

.tblTitle {
  display: flex;
  color: var(--mnq-primary);
  align-items: center;
}

.tblTitle .titleLinkWrap {
  flex: 1;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tblTitle .titleLinkWrap a,
.tblTitle a {
  color: var(--mnq-primary);
}

.recentProjectTbl .tblTitle .titleLinkWrap {
  max-width: calc(50vw - 520px);
}

.projectListTbl .tblTitle .titleLinkWrap {
  max-width: calc(100vw - 950px);
}

.dashboardRightCont .projectListTbl .tblTitle .titleLinkWrap {
  max-width: calc(50vw - 450px);
}

.tblTitle>a:not(:last-child),
.tblTitle>.titleLinkWrap:not(:last-child),
.tblTitle span:not(:last-child) {
  margin-right: 5px;
}

.titleLinkWrap .anticon {
  margin-left: 5px;
}

.tblTitle .anticon+a:not(:first-child) {
  margin-left: 0;
}

.tblTitle a .ant-typography {
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsisTxt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tblTitle .withTooltip {
  display: flex;
}

.tblTitle .withTooltip .ant-typography,
.ant-typography:last-child {
  margin-bottom: 0;
}

.cardHead .withFav,
.cardHead .withFav:not(:disabled):hover,
.cardHead .withFav:not(:disabled):focus,
.tblTitle.withFav .ant-btn {
  height: 22px;
  margin: 0 5px 0 0;
  width: 20px;
}

.pubYes,
.pubNo,
.liveYes,
.liveNo {
  padding: 0 0 0 14px;
  display: inline-flex;
  font-size: 0.75rem;
}

.pubYes {
  background: url(../images/icon-yes.svg) no-repeat 0 center;
}

.liveYes,
.liveNo {
  position: relative;
}

.liveYes::before,
.liveNo::before {
  position: absolute;
  width: 6px;
  height: 6px;
  content: '';
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.liveYes::before {
  background-color: #43AC6A;
}

.liveNo::before {
  background-color: var(--mnq-error);
}

.fldWthDropdown:not(:first-child),
.fldWthDropdown li:not(:first-child) {
  margin-top: 8px;
}

.fldWthDropdown:not(:last-child) {
  margin-bottom: 12px;
}

.fldWthDropdown li {
  position: relative;
  display: flex;
}

.fldWthDropdown li>*:not(:last-child) {
  margin-right: 8px;
  margin-bottom: 0;
}

.fldWthDropdown li .ant-form-item {
  margin-bottom: 0;
}

.roleDropdown li input {
  padding-right: 100px;
}

.fldWthDropdown li .selectValue {
  flex: 1;
}

.roleDropdown li .selectRole {
  width: 100px;
}

.noPaddingBtn,
.noPaddingBtn:disabled {
  padding: 0;
  border: none;
  background: transparent;
  height: auto;
}

.noPaddingBtn:not(:disabled):hover .ant-btn-icon,
.noPaddingBtn:not(:disabled):hover,
.noPaddingBtn:not(:disabled):focus {
  background: transparent;
  color: var(--mnq-primary);
  background-color: transparent;
  border-color: transparent;
}

.noPaddingBtn:not(:disabled):focus .ant-btn-icon {
  color: var(--mnq-primary);
}

.dashboardWrap {
  display: flex;
  width: 100%;
}

.dashboardWrap>.dashboardTbl,
.dashboardWrap .dashboardRightCont {
  flex: 1;
  max-width: 50%;
}

.dashboardWrap .dashboardTbl,
.dashboardWrap .dashboardChart {
  border: 1px solid var(--mnq-light-04);
  border-radius: var(--radius-1);
}

.dashboardWrap .dashboardRightCont {
  margin-left: 24px;
}

.dashboardWrap .dashboardChart {
  width: 100% !important;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.dashboardRightCont .ant-btn {
  padding: 0;
}

.dashboardChart .chartBlk,
.dashboardChart .chartInfo {
  flex: 1;
}

.dashboardChart .chartBlk {
  display: flex;
  justify-content: center;
}

.dbTblHeader {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--mnq-light-04);
}

.dbTblHeader h3 {
  font-size: 1rem;
  margin: 0 16px 0 0;
}

.chartInfo ul {
  width: 300px;
}

.chartInfo ul li {
  display: flex;
  width: 100%;
}

.chartInfo ul div {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid var(--mnq-light-04);
}

.chartInfo ul div:first-child {
  padding-left: 24px;
  position: relative;
  flex: 1;
}

.chartInfo ul div:first-child::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: inherit;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.chartInfo table td.legendIcon {
  position: relative;
  left: -5px;
  padding: 0;
  font-size: 1.25rem;
  border-bottom: none;
}

.dashboardChart .project-chart-top {
  font-weight: 500;
  font-size: 3em;
  fill: #000 !important;
  text-align: center;
}

.dashboardChart .project-chart-bottom {
  font-size: 0.88rem;
  fill: #000;
  transform: translateY(25px);
}

.chartInfo ul div a:focus {
  text-decoration: none;
}

.chartInfo ul div.color1::before {
  background-color: #0079A1;
}

.chartInfo ul div.color2::before {
  background-color: #5C7784;
}

.chartInfo ul div.color3::before {
  background-color: #A41E35;
}

.chartInfo ul div.color4::before {
  background-color: #141414;
}

.chartInfo ul div.projectCount {
  color: var(--mnq-light-01);
  width: 60px;
  text-align: right;
}

.chartInfo ul div.projectPercent {
  width: 60px;
  text-align: right;
}

.fullLoader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  display: none;
}

.mainContent .fullLoader {
  position: absolute;
}

.fullLoader.active {
  display: block;
}

.fullLoader img {
  display: block;
}

.fullLoader .loadingTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
}

.loadAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: rotate 1.5s infinite linear both;
  margin: -30px 0 0 -21px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pageTopSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-picker-footer .btnWrap {
  padding: 6px 16px;
}

.ant-picker-dropdown .ant-picker-footer-extra .btnWrap {
  padding: 10px 0;
}

.btnWrap>* {
  margin-left: 10px;
}

.btnWrap>.calLegend {
  margin: 0 auto 0 0;
}

.calLegend {
  position: relative;
  padding-left: 32px;
  line-height: 1.5rem;
}

.calLegend::after {
  content: '';
  width: 29px;
  height: 2px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: var(--mnq-error-border);
  left: -4px;
  top: 11px;
  opacity: 0.7;
}

.calLegend::before {
  content: '15';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  line-height: 1;
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-1);
}

.btnWrap .anticon-setting,
.btnWrap .actionBtn .anticon-setting:not(:first-child) {
  font-size: 1.25rem;
  display: inline-flex;
  cursor: pointer;
}

.btnWrap .anticon-setting:hover {
  color: var(--mnq-primary);
}

.btnWrap .ant-form-item {
  margin-bottom: 0;
}

.backLink {
  display: flex;
  align-items: center;
  flex: 1;
}

.backLink a {
  text-decoration: none;
}

.pageHeadingWrap h2 .statusIndicator {
  display: inline-block;
  position: relative;
  top: -4px;
  border-color: var(--mnq-dark-01);
}

.backLink .anticon {
  margin-right: 5px;
}

.pageHeadingWrap h2 .anticon {
  margin-right: 10px;
  opacity: 0.5;
  font-size: 1.5rem;
  cursor: pointer;
}

.pageHeadingWrap h2 .anticon:hover {
  opacity: 1;
}

.pageHeadingWrap h2 .anticon.active {
  color: var(--mnq-medium);
  opacity: 1;
}

.pageHeadingWrap h2 .anticon svg,
.backLink .anticon svg {
  display: block;
}

.statusIndicator {
  border-radius: 20px;
  border: 1px solid var(--mnq-light-03);
  font-size: 0.75rem;
  padding: 5px 16px;
  margin-left: 16px;
  font-weight: 400;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tabContainer.previewRightContainer {
  max-width: calc(100vw - 586px);
}

.tabMenu {
  display: flex;
}

.tabMenu button {
  flex: 1;
  text-align: center;
  background-color: var(--mnq-light-05);
  border: 1px solid var(--mnq-light-03);
  border-radius: 4px 4px 0 0;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  min-height: 40px;
}

.tabMenu button.active,
.tabMenu button:not(:disabled):focus {
  background-color: var(--mnq-light-05);
  border-top: 4px solid var(--mnq-primary);
  border-bottom-color: transparent;
}

.tabContent {
  flex: 1;
  border: 1px solid var(--mnq-light-03);
  border-width: 0 1px 1px;
  border-radius: 0 0 var(--radius-1) var(--radius-1);
  padding: 24px;
}

.addQstBlk {
  border: 1px dashed var(--mnq-light-03);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  border-radius: var(--radius-1);
}

.addIcon {
  color: var(--mnq-light-05);
  background: var(--mnq-primary);
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.addQstBlk .questionTypeMenu+p {
  margin-top: 10px;
}

.ant-tree .questionWrapper,
.ant-tree .questionWrapper .ant-tree-switcher {
  display: flex;
}

.ant-tree .questionWrapper {
  align-items: center;
  width: 100%;
}

.ant-tree .questionWrapper .ant-tree-switcher {
  padding: 6px 0;
}

.ant-tree .questionWrapper .ant-tree-node-content-wrapper {
  padding: 6px;
  padding-bottom: 8px;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid var(--mnq-light-02);
  flex: 1;
  border-radius: 0;
}

.leftColWrap .ant-tree .questionWrapper:last-child .ant-tree-node-content-wrapper {
  border: none;
}

.ant-tree .questionWrapper.active .ant-tree-node-content-wrapper,
.ant-tree .questionWrapper:hover .ant-tree-node-content-wrapper {
  border-color: var(--mnq-primary);
  background-color: transparent;
  border-radius: var(--radius-1);
}

.questionWrapper .prevAddIcon .questionTypeMenu,
.questionWrapper .nextAddIcon .questionTypeMenu {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  max-width: 330px;
}

.questionWrapper .prevAddIcon .questionTypeMenu {
  top: 0;
  margin-top: -16px;
}

.questionWrapper .nextAddIcon .questionTypeMenu {
  bottom: 0;
  margin-bottom: -12px;
}

.questionWrapper:hover .questionTypeMenu {
  display: flex;
}

.questionWrapper .questionTypeMenu.active {
  display: flex;
}

.questionWrapper .ant-tree-node-content-wrapper:has(.dragHoverCitation) {
  border-color: var(--mnq-primary);
  background-color: transparent;
}

.qstRowBlk {
  display: flex;
  align-items: flex-start;
}

.qstRowBlk.notAnswered,
.qstRowBlk.notAnswered .buttonWrap button {
  color: var(--mnq-error);
}

.qstRowBlk.notAnswered .qstIconNumber path {
  fill: var(--mnq-error);
}

.bulletDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--mnq-primary);
  margin-right: 5px;
}

.qstRowBlk.notAnswered .bulletDot {
  margin-top: 12px;
}

.qstRowBlk.notAnswered .buttonWrap .resetBtn {
  margin-left: 8px;
}

.qstNumberGap {
  margin-right: 6px;
}

.qstRowBlk.withEditRow {
  padding-right: 220px;
}

.qstRowBlk .titleTxt {
  flex: 1;
}

.qstRowBlk .titleTxt .qstFormBlk {
  line-height: 1.3;
}

.qstRowBlk .titleTxt .qstFormBlk:not(:last-child) {
  margin-bottom: 12px;
}

.qstRowBlk .titleTxt .ansBlk:empty {
  display: none;
}

.qstListWrap .qstRowBlk .titleTxt {
  width: 360px;
}

.qstListWrap .qstRowBlk .titleTxt .titleBtn {
  text-wrap: wrap;
  height: auto;
  min-height: 32px;
  text-align: left;
}

.qstRowBlk .titleTxt .ant-btn,
.qstRowBlk .titleTxt .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  padding: 0;
  background: transparent;
  white-space: inherit;
  text-align: left;
}

.qstRowBlk .titleTxt ul {
  list-style-type: initial;
  margin-left: 16px;
}

.qstRowBlk .titleTxt .buttonWrap {
  display: flex;
  align-items: center;
  min-height: 32px;
}

.qstRowBlk .titleTxt .buttonWrap .cautionBtn {
  margin-left: 5px;
  color: var(--mnq-medium);
}

.qstRowBlk .formAction,
.qstRowBlk .qstActions {
  position: absolute;
  right: 10px;
  display: flex;
  opacity: 0.5;
}

.qstRowBlk .formAction {
  bottom: 6px;
}

.qstRowBlk .qstActions {
  top: 10px;
}

.acceptReject>button:not(:last-child) {
  margin-right: 5px;
}

.currencyFld,
.qstFormBlk .ant-input-number {
  width: 160px;
}

.currencyFld .ant-input-prefix,
.currencyFld .ant-input-suffix {
  color: var(--mnq-light-07);
  font-weight: 400;
}

.formAction.acceptReject {
  width: 140px;
}

.ant-tree .questionWrapper:hover .ant-tree-node-content-wrapper .qstRowBlk .formAction,
.ant-tree .questionWrapper.active .ant-tree-node-content-wrapper .qstRowBlk .formAction,
.ant-tree .questionWrapper:hover .ant-tree-node-content-wrapper .qstRowBlk .qstActions,
.ant-tree .questionWrapper .ant-tree-node-content-wrapper .qstRowBlk .qstActions.active {
  opacity: 1;
}

.qstRowBlk .qstActions>*,
.qstRowBlk .qstActions>.ant-btn-default {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  margin-left: 2px;
}

.qstRowBlk .qstActions>.ant-btn-default {
  border: none;
  padding: 0;
}

.qstRowBlk .qstActions>*:hover,
.qstRowBlk .qstActions>*.active,
.qstRowBlk .qstActions>.ant-btn-default:hover,
.qstRowBlk .qstActions>.ant-btn-default.active {
  background-color: var(--mnq-light-03);
  color: var(--mnq-primary);
}

.qstIconNumber {
  display: flex;
  align-items: center;
  padding-right: 8px;
  min-height: 32px;
}

.qstIconNumber svg {
  margin-right: 5px;
}

.qstForm {
  flex: 1;
}

.coveredFld .ant-input,
.coveredFld .ant-picker,
.coveredFld .ant-input-number,
.coveredFld .ant-select-single .ant-select-selector,
.coveredFld .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.coveredFld .ant-input-affix-wrapper {
  border-radius: 0 var(--radius-1) var(--radius-1) 0;
  margin-left: -1px;
  width: 100%;
}

.jurisdictionFldWrap .coveredFld {
  display: flex;
}

.jurisdictionFldWrap .coveredFld .jurisdictionFld {
  flex: 1;
  margin-left: -1px;
  max-width: 353px;
}

.coveredFld .jurisdictionSelect .ant-select-selector,
.coveredFld .jurisdictionSelect:not(.ant-select-customize-input) .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.jurisdictionFldWrap .coveredFld .jurisdictionSelect {
  width: 200px;
}

.coveredFld .ant-input-number,
.coveredFld .fldSmallWrap {
  width: 100px;
}

.coveredFld .addEditList .ant-input,
.coveredFld .addEditList .ant-picker,
.coveredFld .addEditList .ant-input-number,
.coveredFld .addEditList .ant-input-affix-wrapper {
  border-radius: var(--radius-1);
}

.coveredFld .ant-form-item .ant-form-item-label>label {
  color: inherit;
  background-color: var(--mnq-light-04);
  border: 1px solid var(--mnq-light-03);
  padding-left: 8px;
  border-radius: var(--radius-1) 0 0 var(--radius-1);
  margin: 0;
  height: 32px;
  width: 120px;
  justify-content: center;
}

.coveredFld .ant-form-item .ant-form-item-explain-error,
.coveredFld .ant-form-item {
  margin-bottom: 6px;
}

.coveredFldWrap .coveredFld .ant-form-item {
  margin-bottom: 0;
}

.questionDragHolder {
  color: var(--mnq-primary);
  padding-left: 20px;
}

.recordHeadingWrap {
  display: flex;
  margin-bottom: 16px;
}

.recordHeadingWrap>*:not(:last-child) {
  margin-right: 8px;
}

.showDeleted {
  padding-top: 5px;
  min-width: 130px;
}

.showDeleted button {
  margin-right: 3px;
}

.viewTab {
  display: flex;
}

.viewTab .ant-btn {
  border-radius: 0;
}

.viewTab .ant-btn:first-child {
  border-top-left-radius: var(--radius-1);
  border-bottom-left-radius: var(--radius-1);
}

.viewTab .ant-btn:last-child {
  border-top-right-radius: var(--radius-1);
  border-bottom-right-radius: var(--radius-1);
}

.recordHeadingWrap .selectQst {
  flex: 1;
  min-width: 100px;
  width: 100%;
}

.recordHeadingWrap .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.recordHeadingWrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.recordHeadingWrap .ant-btn.ant-btn-icon-only {
  min-height: 32px;
}

.recordHeadingWrap .ant-select-multiple .ant-select-selection-item {
  height: 22px;
  line-height: 22px;
}

.recordHeadingWrap .ant-input-affix-wrapper {
  padding: 3px 12px;
}

.errorWrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 50px 24px 24px;
  margin: 10px;
}

.errorWrap .errorImg {
  font-size: 0;
  flex: 0 0 100px;
  width: 100px;
  height: 110px;
  background: url(../images/error-img.svg) no-repeat center center / 100%;
  margin-right: 40px;
}

.errorWrap .errorContent {
  width: 100%;
  max-width: 400px;
  border: 1px solid var(--mnq-light-03);
  border-radius: var(--radius-1);
  overflow: hidden;
  padding: 12px;
}

.errorWrap .errorContentInner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.errorWrap .errorContent p {
  margin-bottom: 16px;
}

.errorWrap .errorCode {
  background-color: var(--mnq-light-03);
  color: var(--mnq-primary);
  font-size: 1.25rem;
  padding: 2px 12px;
  margin: -12px -12px 12px;
}

.errorWrap .errorHeading {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--mnq-primary);
  margin-bottom: 12px;
}

.sourceWrapper {
  display: flex;
}

.sourceLeft {
  flex: 0 0 36%;
  width: 36%;
}

.sourceLeft .fldWrap,
.sourceLeft .ant-form-item {
  margin-bottom: 8px;
}

.progressContainer {
  display: flex;
  justify-content: space-between;
}

.progressWrap {
  flex: 1;
  padding-right: 10px;
}

.qstListWrap {
  padding: 16px;
  max-height: calc(100vh - 419px);
  overflow-y: auto;
}

.graphWrap {
  padding: 12px;
  margin-bottom: 16px;
}

.sourceRight {
  flex: 1;
  padding-left: 30px;
}

.sourceHeading {
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
}

.sourceHeading .ant-typography {
  margin-left: 10px;
}

.lastModified {
  margin-left: auto;
}

.qstListWrap,
.graphWrap,
.noData,
.editorItem {
  border: 1px solid var(--mnq-light-03);
  border-radius: var(--radius-1);
}

.editorItem {
  margin-bottom: 6px;
}

.noData {
  padding: 16px;
  text-align: center;
}

.sourceContainer {
  max-height: calc(100vh - 275px);
  overflow-y: auto;
  padding-right: 6px;
}

.ant-tree .questionWrapper .ant-tree-node-content-wrapper:has(.activeMenu) {
  background-color: initial;
  border-color: var(--mnq-primary);
}

.ant-tree .questionWrapper .ant-tree-node-content-wrapper:has(.activeMenu) .qstRowBlk .formAction {
  opacity: 1;
}

.dropdownMenuWrap {
  width: 315px;
}

.dropdownMenuWrap .dropdownMenuItem:hover {
  color: var(--mnq-primary);
}

.dropdownMenuItem svg {
  margin-right: 5px;
}

.dropdownMenuItem:hover svg path {
  fill: var(--mnq-primary);
}

.searchFld {
  width: 260px;
  min-width: 170px;
}

.progressIndicator {
  display: flex;
  align-items: center;
}

.progressIndicator .progressValue {
  flex: 0 0 80px;
  margin-left: 8px;
}

.progressIndicator .progressValue .anticon {
  margin-left: 4px;
}

.coveredFldWrap {
  display: flex;
  margin: 0 0 24px;
}

.coveredFldWrap>* {
  flex: 1;
}

.coveredFldWrap>*:not(:first-child) {
  margin-left: 10px;
}

.innerContentBlk {
  border: 1px solid var(--mnq-light-04);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border-radius: var(--radius-1);
}

.addEditList li {
  display: flex;
  align-items: flex-start;
}

.addEditList li .dragIcon {
  margin-top: 5px;
}

.dragActive .dragIcon {
  background-color: var(--mnq-light-03);
  border-radius: var(--radius-1);
  color: var(--mnq-primary);
}

.addEditList li>* {
  margin-right: 5px;
}

.addEditList li>.ant-btn {
  height: 32px;
}

.addEditList li .ant-input {
  width: 400px;
}

.coveredFld .ant-btn {
  font-size: inherit;
}

.confirmationModal .ant-modal-content {
  padding: 0;
}

.confirmationModal .ant-modal-header,
.confirmationModal .ant-modal-footer {
  padding: 10px 16px;
  margin: 0;
}

.confirmationModal .ant-modal-header {
  border-bottom: 1px solid var(--mnq-light-03);
}

.confirmationModal .ant-modal-footer {
  border-top: 1px solid var(--mnq-light-03);
}

.confirmationModal .ant-modal-close {
  top: 10px;
}

.confirmationModal .ant-modal-body {
  padding: 16px;
}

.ant-modal-body .ant-table-wrapper:last-child {
  margin-bottom: 0;
}

.notificationTxtWrap {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.notificationTxtWrap .notificationIcon {
  flex: 0 0 24px;
  padding-top: 2px;
  font-size: 1rem;
}

.notificationTxtWrap .notificationTxt p:not(:last-child) {
  margin-bottom: 5px;
}

.nameItem {
  display: flex;
  line-height: 1.3;
  color: var(--mnq-primary);
}

.nameItem>a {
  margin-left: 5px;
}

.expanderTitle {
  padding: 4px 8px;
  background-color: var(--mnq-light-04);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.expanderTitle .titleEditableWrap {
  display: flex;
}

.expanderTitle .formAction {
  display: flex;
  width: auto;
}

.expanderTitle .ant-form-item {
  margin: 0;
}

.expanderTitle .titleEditFld {
  flex: 1;
  margin: 0 10px 0 0;
}

.expanderTitleWrap {
  flex: 1;
  margin: 0 5px;
}

.expanderTitleBtns {
  margin-left: auto;
  display: flex;
  align-items: center;
}


.expanderTitle .iconOnlyBtn svg {
  width: 16px;
  height: 16px;
}

.expanderTitle .expanderTitleWrap {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1.2;
}

.expanderTitleTxt:not(:last-child) {
  margin-bottom: 6px;
}

.sourceVersion {
  display: flex;
  align-items: flex-start;
}

.versionTxt,
.outdatedLaw {
  padding: 2px 4px 1px;
  border-radius: var(--radius-1);
  border: 1px solid var(--mnq-primary);
}

.versionTxt {
  background-color: var(--mnq-light-05);
  color: var(--mnq-primary);
  border: 1px solid var(--mnq-primary);
}

.outdatedLaw {
  background-color: var(--mnq-primary);
  color: var(--mnq-light-05);
}

.sourceVersion .statusIndicator {
  background-color: var(--mnq-placeholder);
  color: var(--mnq-light-05);
  border: none;
  border-radius: var(--radius-1);
  margin-left: 0;
  padding: 3px 8px 2px;
}

.sourceVersion>*:not(:last-child) {
  margin-right: 5px;
}

.ant-modal-root .ant-modal-wrap.addModal {
  z-index: 10000;
}

.userCol {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.userCol>span {
  margin-right: 8px;
}

.userCol .linkBtn {
  border: none;
  background: none;
  font-size: 0.75rem;
}

.addQstList {
  padding: 10px 12px;
  border-radius: var(--radius-1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  background-color: var(--mnq-light-05);
}

.addQstList label {
  font-weight: 400;
  line-height: 1.3;
}

.addQstList label.ant-checkbox-wrapper {
  align-items: flex-start;
  display: flex;
}

.addQstList ul,
.addQstList .qstRadioGroup {
  flex: 1;
  overflow-y: auto;
  margin: -10px -10px 0;
  padding: 10px;
}

.addQstList li:not(:last-child) {
  margin-bottom: 5px;
}

.addQstList .btnWrap {
  border-top: 1px solid var(--mnq-light-04);
  margin: 0 -12px -10px;
  padding: 8px 12px;
}

.addQstList>label {
  margin-bottom: 8px;
  font-weight: 500;
}

.addQstList .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.btnWithAction {
  display: flex;
}

.btnWithAction button {
  position: relative;
}

.btnWithAction button:hover,
.btnWithAction button:focus,
.btnWithAction button:focus-visible {
  z-index: 1;
}

.btnWithAction>button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btnWithAction>button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btnWithAction .ant-btn.ant-btn-icon-only .anticon {
  font-size: 0.875rem;
}

.viewCitationBlk ul {
  font-size: 0.75rem;
}

.viewCitationBlk ul li {
  display: flex;
  position: relative;
  margin-bottom: 4px;
  padding-left: 16px;
}

.viewCitationBlk ul li .ant-btn {
  position: absolute;
  left: -2px;
  top: 0;
  height: 20px;
}

.viewCitationBlk ul li .ant-btn,
.viewCitationBlk ul li .ant-btn:not(:disabled):hover,
.viewCitationBlk ul li .ant-btn:not(:disabled):focus {
  margin: 0;
}

.sectionHeading {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
}

.viewCitationBlk>.sectionHeading {
  cursor: pointer;
  display: inline-flex;
}

.viewCitationBlk>.sectionHeading:hover {
  color: var(--mnq-dark-02);
}

.linkedQst {
  margin: 0 0 12px 20px;
}

.linkedQst .sectionHeading {
  margin-bottom: 4px;
  font-size: 0.75rem;
}

.linkedQst li:not(:last-child) {
  margin-bottom: 4px;
}

.qstWithTagWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.qstWithTagWrap+.qstFormBlk {
  margin-top: 5px;
  margin-bottom: 15px;
}

.qstWithTagWrap .buttonWrap {
  margin-right: 8px;
}

.ansBlk {
  padding: 5px 8px 3px;
  background: var(--mnq-light-04);
  display: inline-flex;
}

.clearBtn {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  margin-left: 8px;
}

.historyTable .ant-pagination {
  display: none;
}

.dateRangeWrap {
  display: flex;
  margin-bottom: 12px;
}

.dateRangeWrap .rangeBlk {
  flex: 1;
  display: flex;
  align-items: center;
}

.dateRangeWrap .rangeBlk label {
  margin: 0 8px 0 0;
}

.smallPageWrap {
  width: 700px;
  margin: 0 auto;
}

.profileWrap {
  display: flex;
  margin-bottom: 20px;
}

.profileUpload {
  flex: 0 0 180px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileUpload .ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  margin-bottom: 16px;
  background-color: var(--mnq-primary);
  color: var(--mnq-light-05);
  font-size: 4rem;
}

.profileFldBlk {
  flex: 1;
}

.zoomSliderWrap {
  display: flex;
  align-items: center;
}

.zoomSliderWrap .ant-slider {
  flex: 1;
  margin: 0 10px;
}

.zoomSliderWrap .iconOnlyBtn,
.zoomSliderWrap .iconOnlyBtn:not(:disabled):hover,
.zoomSliderWrap .iconOnlyBtn:not(:disabled):focus {
  margin: 0;
}

.cardWrap {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.cardWrap li {
  width: 20%;
  flex: 0 0 20%;
  padding: 10px;
}

.cardWrap .cardHead {
  border-radius: var(--radius-1) var(--radius-1) 0 0;
  padding: 8px 40px 8px 16px;
  background: var(--mnq-light-04);
  position: relative;
  display: flex;
  align-items: center;
}

.cardWrap .cardContent {
  border: 1px solid var(--mnq-light-04);
  padding: 16px;
  border-radius: 0 0 var(--radius-1) var(--radius-1);
  display: flex;
}

.cardWrap .cardHead .actionBtn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.cardWrap .cardHead h3 {
  font-size: 1rem;
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardWrap .cardHead h3 .anticon {
  font-weight: 400;
  flex: 0 0 16px;
  margin-right: 8px;
}

.cardWrap .cardHead .outdatedLaw {
  font-size: 0.675rem;
  margin-left: 10px;
  background-color: var(--mnq-light-05);
  color: var(--mnq-primary);
  border: 1px solid var(--mnq-primary);
}

.cardWrap .cardContent .projectBlk {
  margin-right: 20px;
}

.numberCount {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
}

.cardWrap .ant-avatar {
  background-color: var(--mnq-light-03);
  color: var(--mnq-dark-02);
  border: 1px solid var(--mnq-light-05);
}

.cardWrap .userAvatar .ant-avatar {
  background-color: var(--mnq-primary);
  border: 1px solid var(--mnq-light-05);
  color: var(--mnq-light-05);
}

.ant-popover-inner-content .userAvatar {
  margin: 0 2px;
}

.verifyMsgWrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verifyMsgWrap .ant-btn-primary {
  display: inline-flex;
}

.cardWrap li.noDataBlk {
  display: flex;
  justify-content: center;
  flex: 0 0 100%;
  padding: 0;
  width: 100%;
}

.offline-state {
  z-index: 1001;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.iframe-disallowed {
  z-index: 1000;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 200px;
}

.google-map {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-size: x-large;
  font-weight: bold;
  background-color: rgb(209, 205, 205);
}

.ant-table-wrapper .ant-table {
  line-height: 1.2;
}

th.hideHeading {
  font-size: 0 !important;
}

.reviewTop {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.quickFilters {
  display: flex;
  flex: 0 0 500px;
  width: 500px;
  margin-left: auto;
}

.quickFilters .selectFilter {
  margin-right: 10px;
  flex: 1;
}

.quickFilters .ant-btn {
  min-width: 136px;
}

.quickResults ul {
  display: flex;
}

.quickResults ul li:not(:last-child) {
  margin-right: 20px;
}

.quickResults ul li>span {
  color: var(--mnq-primary);
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 500;
}

.reviewWrapper,
.timelineWrapper {
  display: flex;
  align-items: flex-start;
}

.reviewLeftCont,
.timelineChart {
  flex: 1;
}

.timelineChart {
  max-width: calc(100vw - 528px);
}

.reviewLeftCont {
  overflow-y: auto;
  padding-right: 8px;
  max-height: calc(100vh - 310px);
}

.reviewFilters,
.timelineFilters {
  flex: 0 0 400px;
  width: 400px;
  padding: 20px;
  border: 1px solid var(--mnq-light-03);
  border-radius: var(--radius-1);
  margin-left: 24px;
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}

.timelineFilters .rowBlk {
  margin: 0 -8px;
}

.timelineFilters .colBlk {
  flex: 1;
  padding: 0 8px;
  margin: 0;
}

.filterResultsWrapper {
  margin-top: 16px;
}

.filterResultsWrapper .blkHeading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 12px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  height: 24px;
}

.accHeading {
  font-size: 1rem;
  font-weight: 500;
}

.headingExtra {
  display: flex;
}

.headingExtra li {
  margin-left: 12px;
  font-size: 1rem;
  color: var(--mnq-primary);
}

.headingExtra li span {
  font-weight: 500;
  color: var(--mnq-dark-02);
}

.reviewQst {
  background-color: var(--mnq-light-03);
  padding: 10px;
  margin: 10px 0;
  display: flex;
}

.reviewQst>svg {
  margin-top: 2px;
}

.reviewQst>*:not(:first-child) {
  margin-left: 5px;
}

.reviewQst .qstTitle {
  flex: 1;
}

.reviewListWrap {
  display: flex;
}

.reviewItem {
  flex: 1;
  display: flex;
  padding: 16px 24px;
}

.reviewItem:not(:first-child) {
  border-left: 1px solid var(--mnq-light-04);
}

.reviewItemLeft {
  flex: 1;
  margin-right: 16px;
}

.itemHeading {
  font-size: 1.25rem;
  color: var(--mnq-primary);
  font-weight: 500;
  margin-bottom: 8px;
}

.reviewItemLeft .statusIndicator {
  margin: 0;
  padding: 4px 12px;
}

.reviewAnsWrap {
  display: flex;
}

.reviewAnsWrap .reviewAnsBlk {
  flex: 1;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 8px 8px;
}

.reviewAnsWrap .reviewAnsBlk>div:not(:last-child) {
  margin-bottom: 8px;
}

.reviewCount {
  flex: 0 0 120px;
}

.citationOptions {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.citationOptions .linkBtn {
  font-size: 0.875rem;
  margin-left: 5px;
}

.reviewQstTwoColWrap {
  margin-bottom: 20px;
}

.reviewQstTwoColWrap ul,
.cautionNoteWrap {
  display: flex;
}

.reviewQstTwoColWrap ul li {
  flex: 1;
  padding: 12px;
  background-color: var(--mnq-light-04);
  border: 1px solid var(--mnq-light-03);
  border-radius: var(--radius-1);
}

.reviewQstTwoColWrap ul li.noMatch,
.cautionNoteWrap .cautionNote.noMatch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cautionNoteWrap .cautionNote {
  flex: 0 0 calc(50% - 10px);
}

.cautionNote {
  padding: 12px;
  background-color: var(--mnq-medium-bg);
  border: 1px solid var(--mnq-medium-border);
  border-radius: var(--radius-1);
}

.reviewQstTwoColWrap ul:not(:last-child) {
  margin-bottom: 8px;
}

.cautionNote.hasError,
.reviewQstTwoColWrap ul li.haveErrorBlk {
  background-color: var(--mnq-error-bg);
  border-color: var(--mnq-error);
  color: var(--mnq-error);
}

.cautionNoteWrap .cautionNote:not(:first-child),
.reviewQstTwoColWrap ul li:not(:first-child) {
  margin-left: 20px;
}

.qstHeading {
  font-size: 1rem;
  font-weight: 500;
}

.qstSubTxt {
  font-size: 0.75rem;
  margin-bottom: 5px;
  opacity: 0.8;
}

.citationTag {
  border-radius: var(--radius-1);
  padding: 2px 5px;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
}

.citationTag,
.ant-select-outlined.ant-select-multiple .ant-select-selection-item,
.ant-select-multiple .ant-select-selection-item {
  background-color: #5C7784;
  color: var(--mnq-light-05);
}

.ant-select-multiple .ant-select-selection-item-remove,
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: inherit;
}

.reviewBlkDesc {
  margin-top: 16px;
  font-size: 0.875rem;
}

.statusWrap,
.statusAction,
.actionBlkWrap {
  display: flex;
  align-items: center;
}

.statusTag {
  margin-right: 5px;
}

.statusAction,
.actionBlkWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.statusAction button,
.actionBlkWrap button {
  height: 16px;
  width: 16px;
}

.actionBlkWrap button.actionBtn {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.innerFldWrap .ant-form-item {
  margin-bottom: 0;
  width: 90px;
  margin-left: auto;
}

.innerFldWrap .ant-input-number .ant-input-number-input,
.innerFldWrap .ant-form-item .ant-form-item-control-input {
  height: 24px;
  min-height: 24px;
}

.ant-tooltip .ant-tooltip-inner {
  line-height: 1.3;
}

.adminTag,
.tagCount {
  margin-left: 5px;
  border-radius: var(--radius-1);
  padding: 2px 4px 1px;
  border: 1px solid var(--mnq-bdr-color);
}

.withIconTitle {
  display: flex;
  align-items: center;
}

.withIconTitle svg {
  margin-left: 5px;
}

.publishWrapper {
  display: flex;
  margin-top: 10px;
}

.publishWrapper .publishSteps {
  flex: 0 0 350px;
  width: 350px;
  padding-right: 20px;
}

.publishWrapper .publishRightContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
}

.publishRightContent .sectionHeader {
  background: var(--mnq-light-03);
  padding: 8px 20px;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: var(--radius-1) var(--radius-1) 0 0;
}

.publishRightContent .sectionFooter {
  padding: 8px 20px;
  border: 1px solid var(--mnq-light-03);
  border-radius: 0 0 var(--radius-1) var(--radius-1);
}

.publishRightContent .sectionContent {
  padding: 20px;
  border: 1px solid var(--mnq-light-03);
  border-width: 0 1px;
  flex: 1;
  overflow: auto;
}

.publishSteps ul li {
  position: relative;
  padding: 5px 0 16px 48px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--mnq-bdr-color);
}

.publishSteps ul li:last-child {
  padding-bottom: 0;
}

.publishSteps ul li .stepBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-round);
  border: 1px solid var(--mnq-bdr-color);
  color: var(--mnq-bdr-color);
  background: var(--mnq-light-05);
}

.publishSteps ul li.active,
.publishSteps ul li.enabled,
.publishSteps ul li.statusDone {
  color: var(--mnq-dark-02);
}

.publishSteps ul li.statusDone .stepBtn {
  background: url(../images/icon-check.svg) no-repeat center center / 16px;
  font-size: 0;
}

.publishSteps ul li.active .stepBtn,
.publishSteps ul li.statusDone .stepBtn {
  background-color: var(--mnq-select-bg);
  border-color: var(--mnq-select-bg);
  color: var(--mnq-light-05);
}

.publishSteps ul li.active .stepBtn:hover,
.publishSteps ul li.statusDone .stepBtn:hover {
  background-color: var(--mnq-primary);
  border-color: var(--mnq-primary);
  color: var(--mnq-light-05);
}

.publishSteps ul li:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 1px;
  top: 38px;
  bottom: 6px;
  left: 15px;
  background: var(--mnq-bdr-color);
}

.publishSteps ul li.active:not(:last-child)::after,
.publishSteps ul li.statusDone:not(:last-child)::after {
  background: var(--mnq-select-bg);
}

.publishSteps ul li.enabled .stepBtn,
.publishSteps ul li.enabled .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--mnq-dark-01);
  border-color: var(--mnq-dark-01);
  color: var(--mnq-light-05);
}

.publishSteps ul li>* {
  cursor: not-allowed;
}

.publishSteps ul li strong {
  font-size: 1rem;
}

.publishSteps ul li.active>*,
.publishSteps ul li.enabled>*,
.publishSteps ul li.statusDone>* {
  cursor: pointer;
}

.checkboxWrap {
  display: flex;
  flex-direction: column;
}

.checkboxWrap label,
.ant-checkbox-wrapper {
  font-weight: 400;
}

.sectionContent .editorBtnWrap {
  top: -20px;
  border-radius: var(--radius-1) var(--radius-1) 0 0;
  border-color: var(--mnq-bdr-color);
}

.sectionContent .editorItem {
  margin-bottom: 0;
  border-color: var(--mnq-bdr-color);
}

.editorBtnWrap .ant-select-single,
.editorBtnWrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
  min-height: 24px;
}

.editorBtnWrap .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 22px;
}

.editorBtnWrap .ant-select .ant-select-arrow {
  transform: translateY(-50%);
  margin-top: 0;
}

.uploadWrap {
  display: flex;
  flex-wrap: wrap;
}

.uploadWrap .ant-upload-wrapper {
  width: 110px;
}

.typeUploaded.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.typeUploaded.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:hover {
  border-color: var(--mnq-success-border);
  background-color: var(--mnq-success-bg);
  cursor: default;
}

.orDivider {
  margin: 8px 0;
  font-weight: 500;
}

.uploadedList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.uploadedList li {
  margin-bottom: 8px;
  border: 1px solid var(--mnq-bdr-color);
  border-radius: var(--radius-1);
  display: flex;
}

.uploadedList li .listTitle {
  flex: 0 0 100px;
  border-right: 1px solid var(--mnq-bdr-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadedList li .listFile {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex: 1;
}

.uploadedList li .listFile .listFileName {
  flex: 1;
  padding-right: 8px;
}

.uploadedList li .listFile .listFileName .anticon {
  margin-right: 8px;
}

.uploadedImgBlk {
  padding: 5px;
  border: 1px solid var(--mnq-bdr-color);
  border-radius: var(--radius-1);
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}

.uploadedImgBlk img {
  max-width: 100%;
  max-height: 100%;
}

.historicalDistributionChartPreview {
  flex: 1;
}

.historicalDistributionChartPreview .axis line {
  display: none;
}

.historicalDistributionChartPreview .axis text {
  display: none;
}

.historicalDistributionChartPreview .slider .parameter-value text {
  fill: #A41E35;
  font-size: 8.3px;
}

.historicalDistributionChartPreview .slider .parameter-value path:focus {
  outline: none;
}

.historicalDistributionChartPreview .axis-x text,
.historicalDistributionChartPreview .axis-y text {
  font-size: 7px;
}

.previewWrapper {
  display: flex;
  padding: 16px;
  border: 1px solid var(--mnq-bdr-color);
  border-radius: var(--radius-1);
}

.previewWrapper .previewLeftContent {
  flex: 0 0 480px;
  width: 480px;
  padding-right: 30px;
}

.previewHistoricalChartWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.previewStatsWrapper {
  flex: 0 0 140px;
  width: 140px;
  padding-left: 16px;
  font-size: 0.675rem;
}

.previewTabMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previewLeftContent .tabContent {
  border-top: 1px solid var(--mnq-light-03);
  padding: 10px;
  margin-top: -1px;
  border-top-right-radius: var(--radius-1);
  max-height: 320px;
  overflow-y: auto;
}

.explore-question-card {
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px;
  background-color: var(--mnq-light-06);
}

.explore-question-card .ant-form-item:last-child {
  margin-bottom: 0;
}

.explore-question-card-top {
  display: flex;
  align-items: center;
}

.explore-question-string {
  flex: 1;
  padding-right: 10px;
}

.previewRightContainer .tabContent {
  padding: 12px;
}

.filterQstList {
  margin-bottom: 12px;
}

.filterQstList li:not(:last-child) {
  margin-bottom: 8px;
}

.optionSelected,
.optionItem {
  display: flex;
}

.optionItem {
  background: var(--mnq-light-04);
  padding: 7px 28px 5px 5px;
  border-radius: var(--radius-1);
  margin-right: 5px;
  position: relative;
  line-height: 1.3;
}

.optionItem .actionIconBtn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: var(--radius-round);
}

.optionItem .actionIconBtn svg {
  width: 8px;
  height: 8px;
}

.previewMapTooltip {
  position: absolute;
  background-color: var(--mnq-light-05);
  padding: 12px;
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-1);
  max-width: 300px;
  display: none;
  font-size: 0.75rem;
}

.previewMapTooltip .closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: var(--radius-round);
  padding: 5px;
}

.previewMapTooltip .btnWrap {
  padding-top: 8px;
}

.topBlk {
  padding: 0 24px 10px 0;
}

.previewQuestionList {
  padding: 0 8px 0 0;
}

.previewQuestionList li {
  margin-bottom: 8px;
}

.previewAnswerBlk {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.previewAnswerBlk:last-child {
  margin-bottom: 0;
}

.previewAnswerBlk>*:not(:last-child),
.previewAnswerBlk>*:not(:last-child):hover,
.previewAnswerBlk>*:not(:last-child):focus {
  margin-right: 5px;
}

.ant-select-selection-item-content {
  padding-top: 1px;
}

.card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.jurCard.card-content {
  max-height: 400px;
  overflow-y: auto;
}

.projectHeadingBlk {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.projectHeading {
  flex: 1;
  padding-right: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.seriesBarWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--mnq-light-03);
  padding-bottom: 12px;
}

.seriesBarWrapper li {
  margin-bottom: 10px;
}

.seriesBarWrapper .seriesBar {
  margin: 0 16px 5px;
  width: calc(100% - 32px);
  height: 6px;
  border: none;
}

.seriesBarWrapper .seriesInfo {
  display: flex;
  justify-content: space-between;
  color: var(--mnq-placeholder);
  font-size: 0.75rem;
}

.headingTxt {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 1rem;
}

.frontFace,
.backFace {
  margin-top: 16px;
  backface-visibility: hidden;
  background-color: #fff;
}

.backFace {
  transform: rotateY(180deg);
}

.backFaceCont p:not(:last-child) {
  margin-bottom: 10px;
}

.backFace .statusIndicator,
.popContent .statusIndicator {
  display: inline-block;
  margin: 0 0 12px;
  padding: 2px 10px 0;
}

.backFace .popContent {
  max-width: 100%;
  max-height: 100%;
  overflow-y: initial;
}

.previewMapArea {
  text-align: center;
}

.spinWrap {
  padding: 0 0 16px;
}

.popContent {
  max-width: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 5px;
}

.popContent li:not(:last-child) {
  margin-bottom: 16px;
}

.citationHeader {
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
}

.citationHeader button {
  height: 16px;
  margin-right: 3px;
}

.citationContent {
  margin: 0 0 16px 22px;
}

.my-masonry-grid {
  gap: 10px;
  display: flex;
  width: auto;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

.card {
  margin-bottom: 10px;
  border: 1px solid var(--mnq-bdr-color);
  border-radius: 5px;
}

.card.filter {
  background-color: var(--mnq-secondary-bg);
  border-color: var(--mnq-secondary);
}

.card.filter1 {
  background-color: var(--mnq-medium-bg);
  border-color: var(--mnq-error-border);
}

.card.filter2 {
  background-color: var(--mnq-primary-bg);
  border-color: var(--mnq-primary);
}

.explore-question-card hr {
  margin: 8px 0;
}

.explore-question-card .ant-form-item {
  margin-bottom: 8px;
}

.notificationTxtWrap a.iconLink,
.tblTitle .titleLinkWrap a.iconLink,
.tblTitle .anticon.activeIcon,
.tblTitle a.iconLink {
  color: var(--mnq-dark-01);
  margin-right: 0;
  cursor: pointer;
  margin-left: 8px;
}

.tblTitle a.iconLink .anticon,
.tblTitle a.iconLink .anticon:hover {
  margin: 0;
}

.notificationTxtWrap a.iconLink,
.tblTitle a.iconLink {
  width: 22px;
  height: 22px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
}

.notificationTxtWrap a.iconLink:hover,
a.iconLink:focus,
.tblTitle a.iconLink:hover,
.tblTitle a.iconLink:hover .activeIcon svg {
  background-color: var(--mnq-light-03);
  color: var(--mnq-primary);
}

.notificationTxtWrap a.iconLink:focus-visible,
.tblTitle a.iconLink:focus-visible {
  outline: 2px solid #c9858a;
  z-index: 1;
}

.notificationTxtWrap a.iconLink:focus-visible,
.tblTitle a.iconLink:focus-visible,
.tblTitle a.iconLink:focus-visible svg {
  color: var(--mnq-primary) !important;
}

.notificationTxtWrap a.iconLink svg,
.tblTitle a.iconLink svg {
  width: 12px;
  height: 12px;
}

.tblTitle a.iconLink .activeIcon svg {
  width: 14px;
  height: 14px;
}

.iconLink svg {
  display: block;
}

div.recentProjects {
  margin-bottom: 0;
}

.labelWithIcon {
  display: flex;
  align-items: center;
  margin: 0 6px 0 0;
}

.recordTimelineWrap {
  max-height: calc(100vh - 450px);
  padding: 0 6px;
  margin: 0 -6px;
  overflow-x: hidden;
  overflow-y: auto;
}

.graphWrap .recordTimelineWrap {
  max-height: 100px;
}

.qstTwoColWrap {
  display: flex;
}

.qstTwoColWrap>* {
  flex: 1;
}

.qstTwoColWrap>.rightColWrap {
  padding-left: 30px;
}

.sampleFormWrap {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.sampleFormWrap>*:not(:last-child) {
  margin-right: 10px;
}

.qstTreeWrap {
  padding: 8px;
  border: 1px solid var(--mnq-light-04);
  border-radius: var(--radius-1);
}

.qstTreeHeading {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: 8px;
}

.qstItems:not(:last-child) {
  margin-bottom: 12px;
}

.samplingList .qstIconNumber,
.qstItems .qstIconNumber {
  min-height: 24px;
}

.samplingList .qstControl {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
}

.ant-tree.qstTree .ant-tree-treenode {
  padding: 0;
}

.ant-tree.qstTree .questionWrapper .ant-tree-switcher {
  padding: 2px 0;
}

.qstOptions {
  margin: 8px 0 0 20px;
}

.qstOptions:last-child .ant-form-item {
  margin: 0;
}

.quickVarBlk {
  display: flex;
  font-weight: 500;
  margin-bottom: 20px;
}

.quickVarBlk>*:not(:first-child) {
  margin-left: 24px;
}

.ant-statistic .ant-statistic-title {
  color: inherit;
  margin-bottom: 0;
}

.ant-statistic .ant-statistic-content {
  font-size: 1.5rem;
  line-height: 2rem;
}

.listHeading {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.listHeading>*:not(:first-child) {
  margin-left: 16px;
}

.listHeading .itemHeading {
  margin-bottom: 0;
}

.mainQstWrap {
  display: flex;
  align-items: flex-start;
}

.mainQstWrap .titleTxt {
  flex: 1;
  padding-top: 2px;
}

.mainQst {
  margin-bottom: 5px;
}

.mainAns {
  font-weight: 500;
}

.recordLists {
  padding-right: 8px;
}

.recordLists>li {
  padding: 16px 0;
  border-bottom: 1px solid var(--mnq-light-04);
}

.recordLists>li:first-child {
  padding-top: 0;
}

.qstTreeWrap,
.recordLists {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.ansChecked,
.ansNotChecked {
  margin-left: 5px;
  font-size: 0.75rem;
}

.ansChecked {
  color: var(--mnq-success-txt);
}

.ansNotChecked {
  color: var(--mnq-error);
}

.codeModalWrap {
  border: 1px solid var(--mnq-bdr-color);
  padding: 16px;
  border-radius: var(--radius-1);
  width: 400px;
  max-width: 96%;
  margin: 20px auto;
}

.codeModalWrap .headingTxt {
  font-size: 0.875rem;
  color: var(--mnq-primary);
  margin-bottom: 10px;
}

.onlyPreviewSection .tabContainer.previewRightContainer {
  max-width: calc(100vw - 568px);
}

.onlyPreviewSection .previewLeftContent .tabContent {
  max-height: calc(100vh - 310px);
}

.passcodeCol {
  display: flex;
  align-items: center;
}

.passcodeCol>span {
  padding: 5px 8px 0 0;
}

.modalContent {
  text-align: center;
}

.modalContent>label {
  margin-bottom: 8px;
}

.passcodeBlk {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.passcodeBlk>label {
  padding: 4px 5px 0 0;
}

.ant-empty-image path {
  stroke: var(--mnq-dark-01);
}

.ant-empty-normal .ant-empty-description {
  color: var(--mnq-dark-01);
}

.previewFilterControl {
  display: none;
}

.searchDownloadWrap {
  display: flex;
}

.searchDownloadWrap .searchBlk {
  padding-right: 12px;
  flex: 1;
}

a.simpleLink:focus-visible {
  color: var(--mnq-dark-02) !important;
  background-color: var(--mnq-light-05);
}

.coveredFld .fldMidWrap .ant-form-item-label>label {
  width: 100px;
}

.passwordFldWrap {
  position: relative;
}

.passwordFldWrap .ant-input-affix-wrapper {
  padding-right: 32px;
}

.passwordFldWrap .iconOnlyBtn {
  position: absolute;
  z-index: 1;
  right: 3px;
  top: 5px;
  width: 22px;
  height: 22px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.passwordFldWrap .iconOnlyBtn:hover,
.passwordFldWrap .iconOnlyBtn:focus-visible {
  background-color: var(--mnq-light-03);
  color: var(--mnq-primary);
  border-radius: 50%;
}

.passwordFldWrap .iconOnlyBtn svg {
  width: 14px;
  height: 14px;
}

.passwordFldWrap .ant-form-item-feedback-icon {
  display: flex;
}

.customDDList {
  width: 100%;
  position: absolute;
  max-height: 145px;
  overflow: auto;
  background: var(--mnq-light-05);
  z-index: 999;
  padding: 5px;
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-1);
}

.customDDItem {
  padding: 6px 10px;
  border-radius: var(--radius-1);
  margin: 1px;
  font-weight: 600;
  cursor: pointer;
}

.customDDItem.active {
  background-color: var(--mnq-light-03);
}

@media (max-width: 1700px) {
  .cardWrap li {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media (max-width: 1350px) {
  .cardContent label {
    font-size: 0.75rem;
  }
}

@media (max-width: 1200px) {
  .previewWrapper .previewLeftContent {
    flex: 0 0 380px;
    width: 380px;
    padding-right: 20px;
  }

  .onlyPreviewSection .tabContainer.previewRightContainer {
    max-width: calc(100vw - 458px);
  }

  .previewHistoricalChartWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .previewStatsWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .previewStatsWrapper>*:not(:last-child) {
    margin: 0 10px 5px 0;
  }
}

@media (max-width: 1023px) {
  h2 {
    font-size: 1.75rem;
  }

  .mainContent {
    padding: 20px;
  }

  .menuControl {
    display: block;
  }

  .headerMenu {
    position: fixed;
    background: var(--mnq-light-05);
    padding: 5px 0;
    top: 50px;
    right: 10px;
    flex-direction: column;
    width: 200px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    border-radius: var(--radius-1);
    display: none;
  }

  .headerMenu.active {
    display: flex;
  }

  .headerMenu li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1px;
  }

  .headerMenu li a {
    border: none;
    color: var(--mnq-dark-02);
    padding: 5px 16px;
  }

  .headerMenu li a:hover,
  .headerMenu li.active a {
    color: var(--mnq-primary);
    background-color: var(--mnq-light-04);
  }

  .userOptions .userEmail {
    display: block;
  }

  .userProfile .userBlk {
    padding-right: 16px;
  }

  .userProfile .userAvatar {
    margin-right: 0;
  }

  .userBlk .userEmail {
    display: none;
  }

  .mainWrapper footer,
  .footerRight {
    display: block;
  }

  .footerSocial {
    margin: 16px 0;
  }

  .previewWrapper {
    flex-direction: column;
  }

  .previewWrapper .previewLeftContent {
    flex: 1;
    width: 100%;
    padding: 0;
  }

  .previewFilterWrap {
    display: none;
  }

  .previewFilterWrap.active {
    display: block;
    position: fixed;
    right: 0;
    left: 16px;
    top: 110px;
    z-index: 999;
    background: #fff;
    padding: 0 10px 0 0;
  }

  .previewFilterWrap.active .previewTabMenu {
    padding-right: 40px;
  }

  .onlyPreviewSection .tabContainer.previewRightContainer {
    max-width: 100%;
  }

  .previewFilterControl {
    display: block;
    width: 28px;
    height: 28px;
    background: var(--mnq-light-05) url(../images/filter.svg) no-repeat center center / 12px;
    cursor: pointer;
    border: 1px solid var(--mnq-bdr-color);
    border-radius: var(--radius-1) 0 0 var(--radius-1);
    font-size: 0;
    position: fixed;
    z-index: 1000;
    right: -1px;
    top: 120px;
  }

  .previewFilterControl.active {
    right: 10px;
    top: 114px;
    width: 32px;
    height: 32px;
    background-image: url(../images/icon-close-2.svg);
    background-size: 16px;
    border-radius: var(--radius-1);
  }

  .onlyPreviewSection .previewLeftContent .tabContent {
    max-height: calc(100vh - 270px);
    padding: 8px;
  }

  .explore-question-card {
    margin-bottom: 6px;
    padding: 6px 8px;
  }

  .pageTopSection .backLink,
  .pageTopSection .backLink a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767px) {
  .my-masonry-grid {
    flex-direction: column;
    gap: 0;
  }

  .my-masonry-grid_column {
    width: 100% !important;
  }
}