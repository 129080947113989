.previewMapArea path.mapPath {
    stroke: #190f25;
    fill: #EBEBE0;
}

#map-zoomer {
    position: absolute;
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    width: 8px;
    height: 100px;
    padding: 0 5px;
    top: 110px;
    left: 22px;
}

#previewWorldMap {
    background: rgb(170, 216, 234);
    border: 1px solid #0079A1;
    border-radius: var(--radius-1);
    display: inline-flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
}

.mapBtnWrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    top: 10px;
}

.mapBtnWrap>button {
    padding: 2px 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    outline: none;
    border-radius: var(--radius-1);
    border-width: 1px;
}

.mapBtnWrap>button.ant-btn {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    margin-bottom: -1px;
}

.mapBtnWrap>button.ant-btn:not(:disabled):focus {
    border-color: var(--mnq-primary);
    z-index: 1;
    color: var(--mnq-primary);
}

.mapBtnWrap>button .anticon:not(:first-child) {
    margin-left: 0;
}